import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoginCredentialsInterface } from '../interfaces/login-credentials.interface';
import { environment } from '../../../../environments/environment';
import { LoginSuccessInterface } from '../interfaces/login-success.interface';
import { UserDataInterface } from '../interfaces/user-data.interface';
import { Router } from '@angular/router';
import { ResponseInterface } from '../../shared/interfaces/response.interface';
import {
  globalEventsActions,
  GlobalEventsHandlerService
} from '../../shared/utilities/services/global-events-handler.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly _userStorageKey: string = '_userStorageKey';
  private _refreshingToken!: boolean;

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _globalEventsHandlerService: GlobalEventsHandlerService
  ) {
    this._logoutListener();
    this._refreshTokenListener();
  }

  set setRefreshingToken(status: boolean) {
    this._refreshingToken = status;
  }

  get getRefreshingToken(): boolean {
    return this._refreshingToken;
  }

  private _logoutListener(): void {
    this._globalEventsHandlerService.globalEventListener.subscribe((gEvent): void => {
      if (gEvent.action === globalEventsActions.logout) {
        this.logout().subscribe();
      }
    });
  }

  private _refreshTokenListener(): void {
    this._globalEventsHandlerService.globalEventListener.subscribe((gEvent): void => {
      if (gEvent.action === globalEventsActions.refreshToken) {
        this.refreshToken().subscribe();
      }
    });
  }

  login(
    credentials: LoginCredentialsInterface
  ): Observable<LoginSuccessInterface> {
    return this._httpClient
      .post<LoginSuccessInterface>(`${environment.apiUrl}auth/sign-in`, {
        email: credentials.email,
        password: credentials.password
      })
      .pipe(
        tap((response: LoginSuccessInterface): void => {
          this._saveLocalUserData({ tokens: response });
          this._redirectToHome();
        })
      );
  }

  private _saveLocalUserData(userData: UserDataInterface): void {
    this.setRefreshingToken = false;
    localStorage.setItem(this._userStorageKey, JSON.stringify(userData));
  }

  private _redirectToHome(): void {
    this._router.navigate(['/home']);
  }

  getAuthToken(): string {
    const userDataString: string | null = localStorage.getItem(
      this._userStorageKey
    );
    if (userDataString) {
      const userData: UserDataInterface = JSON.parse(userDataString);
      return userData?.tokens?.accessToken || '';
    }
    return '';
  }

  getUserTokens(): LoginSuccessInterface {
    const userDataString: string | null = localStorage.getItem(
      this._userStorageKey
    );
    if (userDataString) {
      const userData: UserDataInterface = JSON.parse(
        userDataString
      ) as UserDataInterface;
      return userData.tokens;
    }
    return '' as unknown as LoginSuccessInterface;
  }

  private _clearUserData(): void {
    localStorage.clear();
  }

  /**
   * Para poder actualizar el token de acceso
   * @return LoginSuccessInterface Si no existe retorna vacio
   */
  getRefreshToken(): string {
    const userTokens: LoginSuccessInterface | null = this.getUserTokens();
    if (userTokens) {
      return userTokens?.refreshToken || '';
    }
    return '';
  }

  logout(): Observable<ResponseInterface<unknown>> {
    const body = this.getUserTokens();
    return this._httpClient
      .post<
        ResponseInterface<unknown>
      >(`${environment.apiUrl}auth/sign-out`, body)
      .pipe(
        tap((): void => {
          this._globalEventsHandlerService.dispatchEvent({ action: globalEventsActions.idlStop });
          this._clearUserData();
          this._router.navigate(['login']);
        })
      );
  }

  /**
   * Para actualizar el token que se ha vencido
   */
  refreshToken(): Observable<LoginSuccessInterface> {
    const refreshToken = this.getUserTokens().refreshToken;
    this.setRefreshingToken = true;
    return this._httpClient
      .post<LoginSuccessInterface>(`${environment.apiUrl}auth/refresh-token`, {
        refreshToken: refreshToken
      })
      .pipe(
        tap((response: LoginSuccessInterface): void => {
          this._saveLocalUserData({ tokens: response });
          this._globalEventsHandlerService.dispatchEvent({ action: globalEventsActions.idlStart });
        })
      );
  }

  /**
   * Para limpiar el storage local y redirigir al login
   */
  cleanStorageAndRedirectToLogin(): void {
    this._clearUserData();
    this._router.navigate(['login']);
  }
}
