<!-- Button -->
<div matRipple
     role="button"
    [matMenuTriggerFor]="userActions"
    class="flex flex-col sm:flex-row flex-auto sm:items-center rounded-3xl px-3 py-1">
    <!-- Avatar and name -->
    <div class="flex flex-auto items-center min-w-0">
        <div class="flex flex-col min-w-0 align-items-end">
            <div class="text-md font-semibold tracking-tight leading-7 md:leading-snug truncate">{{ user.name }}</div>
            <div class="text-sm tracking-tight truncate text-secondary">{{ user.email }}</div>
        </div>
        <div class="flex-0 w-9 h-9 rounded-full overflow-hidden ml-2">
            <img alt="usuario"
                 class="w-full h-full object-cover bg-default"
                 [src]="user.avatarUrl ?? 'assets/images/default-avatar.svg'">
        </div>
    </div>
</div>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="logout()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>
