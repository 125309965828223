import { NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { FuseNavigationItem } from '../../../../modules/shared/interfaces/fuse-navigation.interface';
import {
  ItemInterface,
  MenuInterface
} from '../../../../modules/shared/interfaces/menu.interface';
import { RolePermissionsService } from '../../../../modules/shared/services/role-permissions.service';
import { MENU_CONST } from '../../../../modules/shared/constants/menu.constant';
import { environment } from '../../../../../environments/environment';
import { UserInterface } from '../../../../modules/shared/interfaces/user.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectedRoleService } from '../../../../modules/shared/services/selected-role.service';
import { AvailableRolesService } from '../../../../modules/shared/services/available-roles.service';
import { RoleInterface } from '../../../../modules/shared/interfaces/role.interface';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'classic-layout',
  templateUrl: './classic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    NgIf,
    RouterOutlet,
    QuickChatComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatMenuModule,
    NgForOf
  ]
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
  selectedRole!: RoleInterface;
  roles: RoleInterface[] = [];
  appLogo = environment.appLogo;
  menuWithItems: MenuInterface[] = [];
  isScreenSmall: boolean;
  navigation: FuseNavigationItem[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user: UserInterface | null = null;

  /**
   * Constructor
   */
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _rolePermissionsService: RolePermissionsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _selectedRoleService: SelectedRoleService,
    private _availableRolesService: AvailableRolesService
  ) {
    this._rolePermissionsService.rolePermissions$.subscribe(
      (rolePermissions) => {
        this.menuWithItems = MENU_CONST.map((menuModule) => ({
          ...menuModule,
          items: menuModule.items.filter((menuItem) =>
            rolePermissions.some((item) => item.route == menuItem.route)
          )
        })).filter((menuModule) => menuModule.items.length > 0);
        this._fillFuseNavigation();
      }
    );

    this._availableRolesService.roles$.subscribe((roles) => {
      this.roles = roles;
    });

    this._selectedRoleService.role$.subscribe((rol) => {
      this.selectedRole = rol;
    });
  }

  private _fillFuseNavigation(): void {
    this.navigation = [];
    this.navigation.push({
      type: 'group',
      link: 'home',
      title: 'iKaro',
      icon: 'home',
      children: [
        {
          type: 'basic',
          link: 'home',
          title: 'Inicio',
          icon: 'home'
        }
      ]
    });
    this.menuWithItems.forEach((menuModule): void => {
      if (!menuModule?.items?.length) {
        return;
      }
      const newFuseItem: FuseNavigationItem = {
        icon: menuModule.icon,
        title: menuModule.module,
        type: 'group'
      };
      newFuseItem.children = menuModule.items.map(
        (item: ItemInterface): FuseNavigationItem => {
          return {
            type: 'basic',
            link: item.route,
            title: item.name,
            icon: item.icon
          };
        }
      );
      this.navigation.push(newFuseItem);
    });
    this._changeDetectorRef.markForCheck();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
  onRoleControlSelected(rolSelected: RoleInterface) {
    if (rolSelected) {
      this._selectedRoleService.role = rolSelected;

      localStorage.setItem(
        '_selectedRoleId',
        JSON.stringify(parseInt(rolSelected.id))
      );

      localStorage.setItem('_redirectToFirstPath', JSON.stringify(true));

      window.location.reload();
    }
  }
}
