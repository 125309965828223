export const environment = {
  appName: 'CLM',
  appFavIcon:
    'https://academia-user-serverless-deployment.s3.amazonaws.com/files/static/images/favicon.png',
  appLogo: 'https://clm-alcaldia-test.s3.amazonaws.com/files/1708702309252.png',

  colors: {
    primary: '#4454C5',
    primaryRgb: '68, 84, 197',
    secondary: '#F8305A',
    secondaryRgb: '248, 48, 90'
  },
  awsS3Bucket: 'clm-alcaldia-test',

  apiUrl: 'https://api.clm.test.kamilainnovation.co/',
  apiAssistantIA: 'https://iatest.sibundoy.kamilainnovation.co',

  service: {
    organizational: 'https://api.clm.test.kamilainnovation.co/organizational',
    attachment: 'https://api.clm.test.kamilainnovation.co/attachements',
    users: 'https://api.clm.test.kamilainnovation.co/users',
    providers: 'https://api.clm.test.kamilainnovation.co/providers',
    roles: 'https://api.clm.test.kamilainnovation.co/roles',
    files: 'https://api.clm.test.kamilainnovation.co/files',
    preContract: 'https://api.clm.test.kamilainnovation.co/pre-contract',
    macroprocess: 'https://api.clm.test.kamilainnovation.co/macroprocess',
    contracts: 'https://api.clm.test.kamilainnovation.co/contracts',
    stageAttachment: 'https://api.clm.test.kamilainnovation.co/stageattachment',
    accounts: 'https://api.clm.test.kamilainnovation.co/accounts'
  }
};
