import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as htmlToPdfmake from 'html-to-pdfmake';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { MatButtonModule } from '@angular/material/button';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-test-mjc',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './test-mjc.component.html',
    styleUrl: './test-mjc.component.scss'
})
export class TestMjcComponent {
    private readonly httpClient: HttpClient = inject(HttpClient);


    async generatePDF(): Promise<void> {
        /**
         * Provisional agregar fuentes, cambiar
         */
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const contentStringHTML = document.getElementById('content').innerHTML;
        const pdfMakeProviderConfig = htmlToPdfmake(contentStringHTML, {
            imagesByReference: true,
            tableAutoSize:true
        });

        /**
         * Array para almacenar las promesas de las imágenes
         */
        const imagePromises: Promise<void>[] = [];

        /**
         * Recorrer las claves de las imágenes y obtener el base64 de cada una
         */
        Object.keys(pdfMakeProviderConfig.images).forEach((imageKey: string): void => {
            const imageUrl = pdfMakeProviderConfig.images[imageKey];
            const imagePromise = this._getImageBase64(imageUrl).then((base64: string): void => {
                pdfMakeProviderConfig.images[imageKey] = base64;
            }).catch(error => {
                console.error(`Error al obtener el base64 de la imagen ${imageUrl}:`, error);
            });
            imagePromises.push(imagePromise);
        });

        /**
         * Una vez que todas las promesas se han resuelto, generar y descargar el PDF
         */
        Promise.all(imagePromises)
            .then((): void => {
                const pdfMakeConfig = { content: pdfMakeProviderConfig.content, images: pdfMakeProviderConfig.images };
                pdfMake.createPdf(pdfMakeConfig).download();
            })
            .catch(error => {
                console.error('Error al obtener el base64 de las imágenes:', error);
            });
    }


    /**
     * para obtener el blob de la imagen
     * @param url
     */
    private async _getImageBase64(url: string): Promise<string> {
        const blob = await firstValueFrom(this.httpClient.get(url, { responseType: 'blob' }));
        return this.blobToBase64(blob);
    }

    /**
     * Para leer el convetir el blob a base64
     * @param blob
     * @private
     */
    private blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject): void => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }
}
