import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export const globalEventsActions = {
  logout: 'logout',
  idlStop: 'idlStop',
  refreshToken: 'refreshToken',
  idlStart: 'idlStart'
};

export interface GlobalEventsInterface<T> {
  action: string;
  data?: T;
  sender?: string;
}

@Injectable({
  providedIn: 'root'
})
/**
 * Para emitir eventos a traves de toda la App
 */
export class GlobalEventsHandlerService {
  private _globalEventSubject = new Subject<GlobalEventsInterface<unknown>>();
  /**
   * Para escuchar los eventos
   */
  globalEventListener = this._globalEventSubject.asObservable();

  /**
   * Para emitir un evento por todo el SPA
   * @param event
   */
  dispatchEvent<T>(event: GlobalEventsInterface<T>): void {
    this._globalEventSubject.next(event);
  }
}
