import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { RoleInterface } from '../interfaces/role.interface';

@Injectable({
  providedIn: 'root'
})
export class SelectedRoleService {
  private readonly _roleKey: string = '_role';
  private _role: ReplaySubject<RoleInterface | null> =
    new ReplaySubject<RoleInterface | null>(1);

  set role(value: RoleInterface | null) {
    localStorage.setItem(this._roleKey, JSON.stringify(value));

    this._role.next(value);
  }

  get selected(): RoleInterface | boolean {
    const role: string | null = this._getSelectedFromStorage();

    if (role) {
      return JSON.parse(role);
    }
    return false;
  }

  get roleId(): string | number | boolean {
    const item: string | null = this._getSelectedFromStorage();

    if (item) {
      const role: RoleInterface = JSON.parse(item);

      return role.id;
    }

    return false;
  }

  get role$(): Observable<RoleInterface | null> {
    return this._role.asObservable();
  }

  setDefaultRoleFromLocalStorage(roles: RoleInterface[]): void {
    const role: string | null = this._getSelectedFromStorage();
    let roleSelected: RoleInterface | null = null;
    const firstRole = roles[0];

    if (role) {
      const roleSelectedAux: RoleInterface = JSON.parse(role);

      roles.forEach((item) => {
        if (item.id == roleSelectedAux.id) {
          roleSelected = item;
        }
      });

      if (!roleSelected) {
        roleSelected = firstRole;
      }
    } else {
      this.role = firstRole;
      roleSelected = firstRole;
    }

    this._role.next(roleSelected);
  }

  private _getSelectedFromStorage(): string | null {
    const stringRole: string | null = localStorage.getItem(this._roleKey);
    if (stringRole) {
      return stringRole;
    }
    return null;
  }
}
