import { MenuInterface } from '../interfaces/menu.interface';

export const MENU_CONST: MenuInterface[] = [
  {
    module: 'Configuración',
    icon: 'settings',
    order: 1,
    items: [
      {
        name: 'Roles',
        route: '/permissions',
        icon: 'grid_view',
        order: 1
      },
      {
        name: 'Dependencias',
        route: '/organizational/organizational-units',
        icon: 'grid_view',
        order: 2
      },
      {
        name: 'Usuarios',
        route: '/users',
        icon: 'grid_view',
        order: 4
      },
      {
        name: 'Contratistas',
        route: '/proponents',
        icon: 'grid_view',
        order: 5
      },
      {
        name: 'Carpetas',
        route: '/organizational/folders',
        icon: 'grid_view',
        order: 7
      },
      {
        name: 'Anexos',
        route: '/organizational/attachments',
        icon: 'grid_view',
        order: 8
      },
      {
        name: 'Documentos',
        route: '/organizational/documents',
        icon: 'grid_view',
        order: 9
      },
      {
        name: 'Flujos Trabajo',
        route: '/',
        icon: 'grid_view',
        order: 10
      },
      {
        name: 'CDPs',
        route: '/organizational/budget-availability-certificates',
        icon: 'grid_view',
        order: 11
      },
      {
        name: 'Info. Insitutional',
        route: '/organizational/institutional',
        icon: 'grid_view',
        order: 12
      },
      {
        name: 'Otrosí',
        route: '/organizational/otrosi-board',
        icon: 'grid_view',
        order: 13
      },
      {
        name: 'Automatizaciones',
        route: '/organizational/automations-list',
        icon: 'grid_view',
        order: 14
      }
    ]
  },
  {
    module: 'Macroproceso',
    icon: 'account_tree',
    order: 2,
    items: [
      {
        name: 'Precontratos',
        route: '/macroprocess/precontract-board',
        icon: 'grid_view',
        order: 1
      },
      {
        name: 'Contratos',
        route: '/macroprocess/contractual-stage-board',
        icon: 'grid_view',
        order: 2
      },
      {
        name: 'Cuentas',
        route: '/macroprocess/postcontractual-stage-board',
        icon: 'grid_view',
        order: 3
      },
      {
        name: 'Otrosíes',
        route: '/macroprocess/otrosi-stage-board',
        icon: 'grid_view',
        order: 4
      }
    ]
  },
  {
    module: 'Reportes',
    icon: 'settings',
    order: 3,
    items: [
      {
        name: 'Vencimientos',
        route: '/reports/expirations',
        icon: 'grid_view',
        order: 1
      },
      {
        name: 'Reversiones',
        route: '/reports/reversions',
        icon: 'grid_view',
        order: 2
      }
    ]
  },
  {
    module: 'Archivo Digital',
    icon: 'snippet_folder',
    order: 4,
    items: [
      {
        name: 'Terceros',
        route: '/',
        icon: 'grid_view',
        order: 1
      },
      {
        name: 'Contratos',
        route: '/',
        icon: 'grid_view',
        order: 2
      },
      {
        name: 'Cuentas',
        route: '/',
        icon: 'grid_view',
        order: 3
      },
      {
        name: 'Reportes',
        route: '/',
        icon: 'grid_view',
        order: 4
      }
    ]
  }
];
