import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

import { RoleInterface } from '../interfaces/role.interface';

@Injectable({
  providedIn: 'root'
})
export class AvailableRolesService {
  private _roles: ReplaySubject<RoleInterface[]> = new ReplaySubject<
    RoleInterface[]
  >(1);

  constructor() {}

  set roles(value: RoleInterface[]) {
    this._roles.next(value);
  }

  get roles$(): Observable<RoleInterface[]> {
    return this._roles.asObservable();
  }
}
