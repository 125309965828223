import { Component, Inject } from '@angular/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-session-time-out',
  standalone: true,
  imports: [BaseDialogComponent, MatButtonModule],
  templateUrl: './session-time-out.component.html',
  styleUrl: './session-time-out.component.scss'
})
export class SessionTimeOutComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    public matDialogRef: MatDialogRef<SessionTimeOutComponent>
  ) {}
}
