import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { TestMjcComponent } from './modules/test/test-mjc/test-mjc.component';

export const appRoutes: Route[] = [
  {
    path: 'login',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    loadChildren: () => import('./modules/auth/auth.routes').then((m) => m.authRoutes)
  },
  {
    path: 'generate-pdf',
    component: TestMjcComponent,
    data: {
      layout: 'empty'
    }
  },
  {
    path: '',
    resolve: {
      initialData: InitialDataResolver
    },
    component: LayoutComponent,
    data: {
      layout: 'classic'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/home/home.routes').then((m) => m.homeRoutes)
      },
      {
        path: 'organizational',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/organizational/organizational.routes').then(
            (m) => m.organizationalRoutes
          )
      },
      {
        path: 'permissions',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/permissions/permissions.routes').then(
            (m) => m.permissionsRoutes
          )
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/users/users.routes').then((m) => m.usersRoutes)
      },
      {
        path: 'proponents',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/proponents/proponents.routes').then(
            (m) => m.proponentsRoutes
          )
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/reports/reports.routes').then(m => m.reportsRoutes)
      },
      {
        path: 'macroprocess',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/macroprocess/macroprocess.routes').then(
            (m) => m.macroprocessRoutes
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
