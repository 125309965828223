import { Injectable } from '@angular/core';

import { UserTokenInterface } from '../interfaces/user-token.interface';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly _tokenKey: string = '_userStorageKey';

  constructor() {}

  get(): UserTokenInterface | boolean {
    const token = localStorage.getItem(this._tokenKey);

    if (token) {
      return JSON.parse(token);
    }
    return false;
  }

  set(token: UserTokenInterface): void {
    localStorage.setItem(this._tokenKey, JSON.stringify(token));
  }

  delete(): void {
    localStorage.removeItem(this._tokenKey);
  }
}
