import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest
} from '@angular/common/http';
import { LoginService } from '../../auth/services/login.service';
import { inject, Injector, runInInjectionContext } from '@angular/core';
import { SelectedRoleService } from '../services/selected-role.service';
import { RoleInterface } from '../interfaces/role.interface';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, filter, switchMap, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginSuccessInterface } from '../../auth/interfaces/login-success.interface';

/*export const tokenInjectionInterceptor: HttpInterceptorFn = (req, next) => {
  const loginService: LoginService = inject(LoginService);
  const selectedRoleService: SelectedRoleService = inject(SelectedRoleService);
  const authToken: string = loginService.getAuthToken();
  const selectedRole: RoleInterface | boolean = selectedRoleService.selected;

  const authRequest: HttpRequest<unknown> = req.clone({
    setHeaders: {
      authorization: `Bearer ${authToken}`,
      ['x-role-id']:
        selectedRole && typeof selectedRole !== 'boolean' ? selectedRole.id : ''
    }
  });
  return next(authRequest);
};*/

export const tokenInjectionInterceptor: HttpInterceptorFn = (req, next) => {
  const authService: LoginService = inject(LoginService);
  const injector = inject(Injector);
  const notificationsService: ToastrService = inject(ToastrService);
  const tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  const authRequest: HttpRequest<unknown> = addTokenToRequest(req);
  return next(authRequest).pipe(
    catchError((err: HttpErrorResponse) => {
      const refreshToken = authService.getRefreshToken();
      switch (err.status) {
        case 401:
          /**
           * Para renovar el token y no permitir doble peticion de renovacion
           */
          if (refreshToken && !authService.getRefreshingToken) {
            /**
             * Resetea para las siguiente llamadas
             */
            tokenSubject.next('');

            return authService.refreshToken().pipe(
              switchMap((newToken: LoginSuccessInterface) => {
                const updatedRequest = runInInjectionContext(injector, () =>
                  addTokenToRequest(authRequest, newToken.accessToken)
                );
                tokenSubject.next(newToken.accessToken);
                return next(updatedRequest);
              }),
              catchError((refreshError) => {
                return throwError(refreshError);
              })
            );
          }

          /**
           * Para reintentar las peticiones que ocurran mientras se renueva el token
           */
          if (refreshToken && authService.getRefreshingToken) {
            return tokenSubject.pipe(
              filter((token: string): boolean => token !== ''),
              take(1),
              switchMap((token: string) => {
                return next(
                  runInInjectionContext(injector, () =>
                    addTokenToRequest(authRequest, token)
                  )
                );
              })
            );
          }

          /**
           * Para hacer logout si ocurre algun error mientras se renueva
           */
          notificationsService.error(
            err?.error?.message || 'Algo anda mal',
            'Error de autenticación'
          );
          authService.cleanStorageAndRedirectToLogin();
          return throwError(err);
        case 403:
          notificationsService.error(
            err?.error?.message || 'Algo anda mal',
            'No estás autorizado'
          );
          return throwError(err);
        default:
          return throwError(err);
      }
    })
  );
};

/**
 * Para agregar los headers a las peticiones Api
 * @param req
 * @param refreshToken
 */
export const addTokenToRequest = (
  req: HttpRequest<unknown>,
  refreshToken?: string
): HttpRequest<unknown> => {
  const authService: LoginService = inject(LoginService);
  const authToken: string = refreshToken ?? authService.getAuthToken();
  const selectedRoleService: SelectedRoleService = inject(SelectedRoleService);
  const selectedRole: RoleInterface | boolean = selectedRoleService.selected;
  return req.clone({
    setHeaders: {
      authorization: `Bearer ${authToken}`,
      ['x-role-id']:
        selectedRole && typeof selectedRole !== 'boolean' ? selectedRole.id : ''
    }
  });
};
