import { Injectable } from '@angular/core';

import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedService {
  constructor(private _tokenService: TokenService) {}

  run(): boolean {
    const token = this._tokenService.get();
    return token === false ? false : true;
  }
}
