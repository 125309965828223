import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';

import { PermissionInterface } from '../interfaces/permission.interface';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionsService {
  private _rolePermissions = new ReplaySubject<PermissionInterface[]>(1);
  /**
   * Setter & getter for role permissions
   *
   * @param value
   */
  set rolePermissions(value: PermissionInterface[]) {
    // Store the value
    this._rolePermissions.next(value);
  }

  get rolePermissions$(): Observable<PermissionInterface[]> {
    return this._rolePermissions.asObservable();
  }
}
