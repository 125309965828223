import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ResponseInterface } from '../interfaces/response.interface';

export const notificationsInterceptor: HttpInterceptorFn = (request, next) => {
  const toastrService: ToastrService = inject(ToastrService);
  return next(request).pipe(
    /* eslint-disable */
    tap((event: any): void => {
      const apiResponse = event?.body as ResponseInterface<unknown>;
      if (event instanceof HttpResponse && apiResponse?.message) {
        toastrService.success(apiResponse.message, 'Éxito');
      }
    }),
    catchError((error) => {
      toastrService.error(
        error?.error?.message || 'Algo anda mal, contacta al soporte',
        'Error'
      );
      return throwError(error);
    })
  );
};
