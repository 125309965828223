<div class="app-base-dialog -m-6">
  @if (title) {
    <div class="app-base-dialog-title-container">
      <div class="app-base-dialog-title">{{ title }}</div>

      <button *ngIf="showCloseButton" mat-icon-button type="button" [tabIndex]="-1" (click)="close()">
        <mat-icon style="color: white">close</mat-icon>
      </button>
    </div>
  }

  <div class="app-base-dialog-content">
    <ng-content select="[content]" />
  </div>

  <div class="app-base-dialog-footer">
    <ng-content select="[footer]" />
  </div>
</div>
