import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { PermissionInterface } from '../interfaces/permission.interface';

@Injectable({
    providedIn: 'root'
})
export class ShortcutsCLMService {

    constructor(
        private _httpClient: HttpClient
    ) {
    }

    getBookmarks(userId: number): Observable<ResponseInterface<PermissionInterface[]>> {
        return this._httpClient.get<ResponseInterface<PermissionInterface[]>>(`${environment.apiUrl}permission-bookmarks/${userId}`);
    }

    saveBookmark(userId: number, permissionId: number): Observable<ResponseInterface<PermissionInterface>> {
        return this._httpClient.post<ResponseInterface<PermissionInterface>>(`${environment.apiUrl}permission-bookmarks`, {
            id: userId,
            permissionId: permissionId
        });
    }

    deleteBookmark(id: number): Observable<ResponseInterface<any>> {
        return this._httpClient.delete<ResponseInterface<any>>(`${environment.apiUrl}permission-bookmarks/${id}`);
    }
}
