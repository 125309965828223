<!-- Shortcuts toggle -->
<button
    mat-icon-button
    (click)="openPanel()"
    #shortcutsOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #shortcutsPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="flex items-center text-lg font-medium leading-10">
                <span class="">Accesos directos</span>
                <ng-container *ngIf="mode !== 'view'">
                    <span class="ml-1">
                        <ng-container *ngIf="mode === 'add'">- Agregar nuevo</ng-container>
                        <ng-container *ngIf="mode === 'modify' || mode === 'edit'">- Editando</ng-container>
                    </span>
                </ng-container>
            </div>
            <div class="ml-auto">

                <!-- View mode -->
                <ng-container *ngIf="mode === 'view'">
                    <!-- Enter 'add' mode -->
                    <button
                        mat-icon-button
                        (click)="newShortcut()"
                        [matTooltip]="'Agregar acceso directo'">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    </button>
                </ng-container>

                <!-- Modify mode -->
                <ng-container *ngIf="mode === 'modify'">
                    <!-- Enter 'view' mode -->
                    <button
                        mat-icon-button
                        (click)="changeMode('view')"
                        [matTooltip]="'Exit edit mode'">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                    </button>
                </ng-container>

                <!-- Add mode -->
                <ng-container *ngIf="mode === 'add'">
                    <!-- Enter 'view' mode -->
                    <button
                        mat-icon-button
                        (click)="changeMode('view')"
                        [matTooltip]="'Cancelar'">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                    </button>
                </ng-container>

                <!-- Edit mode -->
                <ng-container *ngIf="mode === 'edit'">
                    <!-- Enter 'modify' mode -->
                    <button
                        mat-icon-button
                        (click)="changeMode('modify')"
                        [matTooltip]="'Cancelar'">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card">

            <!-- View mode -->
            <ng-container *ngIf="mode === 'view' || mode === 'modify'">

                <!-- Shortcuts -->
                <div class="grid grid-cols-2 grid-flow-row">
                    <!-- Shortcut -->
                    <ng-container *ngFor="let shortcut of favorites; trackBy: trackByFn">
                        <div class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                            <!-- Normal links -->
                            <div
                                class="flex flex-col items-center justify-start w-full h-full py-6 no-underline">
                                <div class="w-full text-end px-3">
                                    <button mat-icon-button (click)="delete(shortcut)"><mat-icon>delete</mat-icon></button>
                                </div>
                                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                            </div>
                            <!-- Link content template -->
                            <ng-template #linkContent>
                                <a [href]="shortcut.route" class="flex flex-col items-center justify-start w-full h-full no-underline">
                                    <div class="relative flex shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full bg-gray-100 dark:bg-gray-700">
                                        <mat-icon
                                            class="z-10"
                                            [ngClass]="{'group-hover:opacity-0': mode === 'modify'}"
                                            [svgIcon]="shortcut.icon ?? 'heroicons_solid:link'"></mat-icon>
                                    </div>
                                    <div class="font-medium text-center">{{shortcut.name}}</div>
                                    <div class="text-md text-center text-secondary">{{shortcut.description}}</div>
                                </a>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>

                <!-- No shortcuts -->
                <ng-container *ngIf="!favorites || !favorites.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                            <mat-icon
                                class="text-primary-700 dark:text-primary-50"
                                [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">No hay accesos directos</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Agrega accesos directos, aparecerán aquí.</div>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Add/Edit mode -->
            <ng-container *ngIf="mode === 'add' || mode === 'edit'">
                <form
                    class="p-6"
                    [formGroup]="shortcutForm">
                    <mat-form-field class="w-full">
                        <mat-label>Seleccionar acceso directo</mat-label>
                        <input type="text"
                               placeholder="Seleccionar acceso directo"
                               matInput
                               [formControlName]="'permissionId'"
                               [matAutocomplete]="auto">
                        <mat-autocomplete [autoActiveFirstOption]="true" #auto="matAutocomplete" [displayWith]="displayFnShortcuts">
                            @for (option of filteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                            }
                        </mat-autocomplete>
                        <mat-error *ngIf="shortcutForm.get('permissionId').hasError('required')">Selecciona un acceso directo</mat-error>
                    </mat-form-field>
                    <!-- Actions -->
                    <div class="flex items-center justify-end mt-4">
                        <button
                            class="mr-2"
                            *ngIf="mode === 'edit'"
                            mat-flat-button
                            type="button"
                            (click)="delete()">
                            Delete
                        </button>
                        <button
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="!shortcutForm.valid"
                            type="button"
                            (click)="save()">
                            <ng-container *ngIf="mode === 'add'">Agregar</ng-container>
                            <ng-container *ngIf="mode === 'edit'">Actualizar</ng-container>
                        </button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</ng-template>
