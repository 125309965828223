import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { UserInterface } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: ReplaySubject<UserInterface> =
    new ReplaySubject<UserInterface>(1);

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: UserInterface) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<UserInterface> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user
   *
   * @param user
   */
  update(user: UserInterface): Observable<unknown> {
    return this._httpClient
      .patch<UserInterface>('api/common/user', { user })
      .pipe(
        map((response: UserInterface) => {
          this._user.next(response);
        })
      );
  }
}
