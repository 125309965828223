import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-base-dialog',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, NgIf],
  templateUrl: './base-dialog.component.html',
  styleUrl: './base-dialog.component.scss'
})
export class BaseDialogComponent {
  @Input() title: string | null = null;
  @Input() showCloseButton: boolean = true;

  @Output() closeDialog = new EventEmitter();

  constructor(public matDialogRef: MatDialogRef<BaseDialogComponent>) {}

  close(): void {
    this.closeDialog.emit();
    this.matDialogRef.close();
  }
}
