import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Router, UrlTree } from '@angular/router';
import { CanActivate, CanActivateChild } from '@angular/router';

import { IsAuthenticatedService } from '../services/is-authenticated.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,

    private _isAuthenticatedService: IsAuthenticatedService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.check();
  }

  check(): boolean {
    const isAuth = this._isAuthenticatedService.run();

    if (isAuth) {
      return true;
    }
    this._router.navigate(['/login']);

    return false;
  }
}
