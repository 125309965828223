import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionTimeOutComponent } from '../components/session-time-out/session-time-out.component';
import { Observable, of, Subscription } from 'rxjs';
import { globalEventsActions, GlobalEventsHandlerService } from './services/global-events-handler.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Para gestionar el tiempo de inactividad del usuario
 */
export class SessionTimeOutService {
  private _timeoutSubscription!: Subscription;
  private _timeoutWarningSubscription!: Subscription;
  private _message!: string;
  /**
   * Tiempo máximo que el usuario puede permanecer inactivo
   * @private
   */
  private readonly _idleTotalTime: number = 14 * 60;

  /**
   * Cuando reste este tiempo se muestra el modal de inactividad, son segundos
   * @private
   */
  private readonly _idleWarningTime: number = 30;
  private _dialogRef!: MatDialogRef<SessionTimeOutComponent>;

  constructor(
    private _ngIdleService: Idle,
    private _globalEventsHandlerService: GlobalEventsHandlerService,
    private _matDialog: MatDialog
  ) {
    this._sessionTimeOutStopListener();
    this._sessionTimeOutStartListener();
  }

  private _sessionTimeOutStopListener(): void {
    this._globalEventsHandlerService.globalEventListener.subscribe((gEvent): void => {
      if (gEvent.action === globalEventsActions.idlStop) {
        this.sessionTimeOutStop();
      }
    });
  }

  private _sessionTimeOutStartListener(): void {
    this._globalEventsHandlerService.globalEventListener.subscribe((gEvent): void => {
      if (gEvent.action === globalEventsActions.idlStart) {
        this.sessionTimeOutStart();
      }
    });
  }

  /**
   * Para configurar los eventos del contador de inactividad
   * @private
   */
  private _configIdle(): void {
    this._ngIdleService.setIdle(this._idleTotalTime);
    this._ngIdleService.setTimeout(this._idleWarningTime);
    this._ngIdleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    /**
     * Para cerrar el modal y la sesión si el usuario no la extendió en el modal
     */
    this._timeoutSubscription = this._ngIdleService.onTimeout
      .subscribe((): void => {
      if (this._dialogRef) {
        this._dialogRef.close();
        this._dialogRef = null as unknown as MatDialogRef<SessionTimeOutComponent>;
      }
      this._globalEventsHandlerService.dispatchEvent({ action: globalEventsActions.logout });
    });

    /**
     * Para escuchar el tiempo crítico de espera y desplegar el modal
     */
    this._timeoutWarningSubscription = this._ngIdleService.onTimeoutWarning
      .subscribe(
      (countdown: number): void => {
        this._ngIdleService.clearInterrupts();
        this._message = `Cerrando sesión en ${countdown} segundos debido a la inactividad.`;
        !this._dialogRef && this._openDialog();
        if (this._dialogRef?.componentInstance?.data?.message) {
          this._dialogRef.componentInstance.data.message = this._message;
        }
      }
    );
  }

  /**
   * Para abrir el dialog y mostrar el tiempo restante
   * Permite que el usuario extienda la sesion
   * @private
   */
  private _openDialog(): void {
    this._dialogRef = this._matDialog.open(SessionTimeOutComponent, {
      disableClose: true,
      data: {
        message: this._message
      }
    });
    this._dialogRef.afterClosed().subscribe((result): void => {
      /**
       * Para reestablecer el contador
       * result => true Permite que la sesión continue
       */
      if (result) {
        this._dialogRef =
          null as unknown as MatDialogRef<SessionTimeOutComponent>;
        this._globalEventsHandlerService.dispatchEvent({ action: globalEventsActions.refreshToken });
      }
    });
  }

  /**
   * Para iniciar el contador de inactividad
   * Se inicial desde el resolver de la App
   */
  sessionTimeOutStart(): Observable<unknown> {
    this.sessionTimeOutStop();
    this._configIdle();
    this._ngIdleService.watch();
    return of()
  }

  /**
   * Para cancelar la deteccion de inactividad
   */
  sessionTimeOutStop(): void {
    this._cleanUpSubscriptions();
    this._ngIdleService.stop();
  }

  private _cleanUpSubscriptions(): void {
    if (this._timeoutSubscription) {
      this._timeoutSubscription.unsubscribe();
    }
    if (this._timeoutWarningSubscription) {
      this._timeoutWarningSubscription.unsubscribe();
    }
  }
}
