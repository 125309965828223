<div id="content">
    <table style="width: 100%; height: 100%;">
        <tbody>
        <tr>
            <td><p style="text-align:center;"><span class="text-small"><strong>MUNICIPIO DE SIBUNDOY</strong></span></p>
            </td>
            <td>Código: SGG-2024</td>
        </tr>
        <tr>
            <td rowspan="2"><p style="text-align:center;">SECRETARÍA GENERAL Y DE GOBIERNO</p></td>
            <td>Versión: 01</td>
        </tr>
        <tr>
            <td>Fecha: 16/01/2024</td>
        </tr>
        </tbody>
    </table>


    <table style="width:100%;">
        <tr>
            <th style="width:20%">Header 1</th>
            <th style="width:80%">Header 1</th>
        </tr>
        <tr>
            <td style="text-align:center" height="50">Cell A1</td>
            <td style="text-align:center" height="50">Cell A1</td>
        </tr>
    </table>

    <table style="width:100%;">
        <tr>
            <td style="width:20%">col1</td>
            <td style="width:80%">Col2</td>
        </tr>
    </table>


    <h1>Ejemplo de PDF con Contenido</h1>
    <p>Este es un ejemplo de cómo renderizar contenido HTML a un PDF utilizando PDFMake.</p>
    <img src="assets/images/logo-as.png" alt="Imagen">
</div>
<button mat-raised-button color="primary" (click)="generatePDF()">Generar PDF</button>
