import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetInitDataService } from './modules/shared/services/get-init-data.service';
import { SessionTimeOutService } from './modules/shared/utilities/session-time-out.service';
import { ResponseInterface } from './modules/shared/interfaces/response.interface';
import { InitDataInterface } from './modules/shared/interfaces/init-data.interface';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver {
    constructor(
        private _getInitDataService: GetInitDataService,
        private _sessionTimeOutService: SessionTimeOutService
    ) {}

    resolve(): Observable<ResponseInterface<InitDataInterface>> {
        this._sessionTimeOutService.sessionTimeOutStart();
        return this._getInitDataService.run();
    }
}
