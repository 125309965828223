import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { RoleInterface } from '../interfaces/role.interface';
import { ResponseInterface } from '../interfaces/response.interface';
import { InitDataInterface } from '../interfaces/init-data.interface';
import { PermissionInterface } from '../interfaces/permission.interface';

import { UserService } from './user.service';
import { SelectedRoleService } from './selected-role.service';
import { AvailableRolesService } from './available-roles.service';
import { RolePermissionsService } from './role-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class GetInitDataService {
  constructor(
    private _router: Router,
    private _httpClient: HttpClient,

    private _userService: UserService,
    private _selectedRoleService: SelectedRoleService,
    private _availableRolesService: AvailableRolesService,
    private _rolePermissionsService: RolePermissionsService
  ) {}

  run(): Observable<ResponseInterface<InitDataInterface>> {
    return this._httpClient
      .get<
        ResponseInterface<InitDataInterface>
      >(`${environment.service.users}/init-data`)
      .pipe(
        map((response) => {
          // Agregando los datos del usuario
          this._userService.user = {
            id: response.data.user.id,
            name: response.data.user.name,
            lastName: response.data.user.lastName,
            avatarUrl: response.data.user.avatarUrl,
            email: response.data.user.email,
            password: '',
            isActive: response.data.user.isActive
          };

          // Agregando los roles
          const roles: RoleInterface[] = [];

          response.data.userRoles.map((userRole) => {
            roles.push(userRole.role);
          });

          if (roles.length) {
            this._selectedRoleService.setDefaultRoleFromLocalStorage(roles);
          }

          this._availableRolesService.roles = roles;

          // Agregando los role permissions
          const rolePermissions: PermissionInterface[] = [];

          response.data.rolePermissions.map((rolePermission) => {
            rolePermissions.push(rolePermission.permissions);
          });

/*          if (rolePermissions.length) {
            setTimeout(() => {
              this._rolePermissionsService.rolePermissions = rolePermissions;
            }, 300);
          }*/

            this._rolePermissionsService.rolePermissions = rolePermissions;

          return response;
        }),
        catchError((error: HttpErrorResponse) => this._handleError(error))
      );
  }

  private _handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this._router.navigate(['/login']);
    }

    return throwError(error);
  }
}
